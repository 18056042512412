// Step 1: Import your component
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { StaticImage } from "gatsby-plugin-image";
import LayoutFlex from "../components/LayoutFLex";
import Figure from "../components/Figure";
import Container from "../components/Container"


const pageStyles = {
  color: "#232129",
  fontFamily: "Open Sans Condensed",
};

// Step 2: Define your component
const TherapyPage = () => {
  return (
    <main style={pageStyles}>
      <title>Lebens- und Sozialberatung</title>
      <Header />

      <LayoutFlex style={{
            padding: "2em",
            width: "50%",
          }}>
        <div className="headdings">
          <h1>Lebens- und Sozialberatung</h1>
        </div>
        <StaticImage 
        alt="Kunst therapie"
        src={"../images/puzzle.jpg"}
         placeholder="blurred"
         layout="fixed"
         width={700}
         height={400}
        />
      </LayoutFlex>
      <Figure>
        <blockquote>
        <cite>„Wenn Sie sich in einem Loch befinden, hören Sie auf zu graben.“</cite>
       </blockquote>
        <figcaption>Will Rogers</figcaption>
      </Figure>



        

        
      
      <Container >
        <h3>Über Lebens- und Sozialberatung:</h3>
        <div>
          <p>
          Lebens- und Sozialberatung/psychologische Beratung gehört zu den Säulen der Gesundheitsvorsorge in Österreich und umfasst zahlreiche Themen in allen Lebenslagen.
          </p>
          <p>
          Individuelle Beratung und Begleitung in jeder Lebenssituation.
          </p>
          <p>
          Die Lebensberatung bietet vielfältige Hilfestellungen, mittels kreativer und lösungsorientierter Methoden. 
          </p>
          <p>
          Das Ziel der Lebensberatung ist gemeinsam die Möglichkeiten und Lösungen zu erarbeiteten, die zu einer aktiven und positiven Lebensgestaltung und Alltagsbewältigung führen.
          </p>
        </div>

      </Container>
      <Footer />
    </main>
  );
};

// Step 3: Export your component
export default TherapyPage;
